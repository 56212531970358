<template>
  <div id="station">
    <div class="amap-marker" style="position: absolute; display: block">
      <div
        style="
          z-index: 1;
          border-radius: 5px;
          border: 2px solid black;
          color: white;
          bottom: 5px;
          width: 143.5px;
          transform: translate(-71.75px, -35px) scale(1) rotate(0deg);
        "
      >
        <div
          style="
            background-color: #67ce0b;
            text-align: center;
            padding: 0px 2px;
            margin: 0px;
            height: 20px;
            border-radius: 2px;
          "
          :style="{ backgroundColor: bgColor,color:fgColor }"
        >
          ★
          <span style="display: inline-block;" :style="{color:fgColor }">
            {{ stationData.station + ' ' + (stationData.pm10 ? stationData.pm10 : '--') }}</span
          >
        </div>
        <div
          class="arrow"
          style="
            background: url('label.png') 0px 0px no-repeat;
            position: absolute;
            width: 6px;
            height: 15px;
            top: 22px;
            left: 66.75px;
            overflow: hidden;
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StationPage',
  data() {
    return {
      stationData: { station: '', pm10: '' },
    }
  },
  computed: {
    intensity() {
      return new colorIntensity.getIntensity({
        dataType: 'pm10',
        max: 700,
        min: 0,
        colorType: 'static',
      })
    },
    bgColor() {
        return this.intensity.bgcolor.getColor(this.stationData.pm10)
    },
    fgColor() {
        return this.intensity.fgcolor.getColor(this.stationData.pm10)
    },
  },
}
</script>
