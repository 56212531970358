import request from '@/utils/request'
import axios from "axios";

// const baseurl = "http://192.168.2.25:7080";
// const baseurl = "https://qd.rmtsns.novaecs.com/api";
const baseurl = process.env.VUE_APP_API_BASE_URL;
// const baseurl = "https://qd.sensing.hw.novaecs.com/api";

const guokong = "https://gf.novaecs.com/api/gf_manage/station_data/";
const bigApi = {
  DistrictBareSoil: '/big/v2/getDistrictIncludeTownBareSoilForBig',
  Download: '/big/v2/exportBareSoilDetail',
}

function getAreasData(dataTime) {
  return axios({
    method: "get",
    url: `${baseurl}/big/getAllBareSoilAreaForBig/${dataTime}`,
  });
}

function getDetail(parameter) {
  return axios({
    method: "get",
    url: `${baseurl}/big/v2/getSingleBareSoilDetail`,
    params: parameter
  });
}

function getRemoteSenseTimeList() {
  return request({
    method: "get",
    url: `${baseurl}/big/getRemoteSenseTimeList`,
  });
}

function download(parameter) {
  return axios({
    method: "get",
    url: `${baseurl}/big/v2/exportBareSoilDetail`,
    responseType: "blob",
    params: parameter
  });
}
// function download (parameter) {
//   return request({
//     url: `${baseurl}${bigApi.Download}`,
//     method: 'get',
//     response: "blob",
//     params: parameter
//   })
// }

function getStationDataList(data) {
  return axios({
    method: "get",
    url: `${guokong}`,
    params: data,
  });
}

function getRegionTreeList() {
  return axios({
    method: "get",
    url: `${baseurl}/big/getRegionTree`,
  });
}

function getRegionBoundary(ids) {
  return request({
    method: "get",
    url: `/big/getRegionBoundary/${ids}`,
  });
}

function uploadInspectionRecords(params) {
  console.log("🚀 ~ file: index.js:58 ~ uploadInspectionRecords ~   console.log:", params);
  //添加请求头
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios({
    url: `${baseurl}/big/uploadInspectionRecords`,
    method: "post",
    data: params,
    headers: headers,
  });
}

function getPatrolDetail(gid) {
  return axios({
    method: "get",
    url: `${baseurl}/big/getPatrolDetail/${gid}`,
  });
}

function getDistrictBareSoil (parameter) {
  return request({
    url: `${baseurl}${bigApi.DistrictBareSoil}`,
    method: 'get',
    params: parameter
  })
}

// function getBdRegionBoundary(parameter) {
//   return request({
//     url: `api_region_search/v1/`,
//     method: "get",
//     params:parameter,
//     baseUrl:'https://api.map.baidu.com/'
//   });
// }

// function getGdRegionBoundary(parameter) {
//   return request({
//     url: `v3/config/district`,
//     method: "get",
//     params:parameter,
//     baseURL:'https://restapi.amap.com/'
//   });
// }

function getBdRegionBoundary(boundaryName) {
  return request({
    url: `geoTools/getBdRegionBoundary/${boundaryName}`,
    method: "get",
  });
}

function getGdRegionBoundary(boundaryName) {
  return request({
    url: `geoTools/getGdRegionBoundary/${boundaryName}`,
    method: "get",
  });
}

function getThirdSelectItems() {
  return request({
    url: `big/getThirdDataTitle`,
    method: "get",
  });
}

function getThirdData(parameter) {
  return request({
    url: `big/getThirdData`,
    method: "get",
    params: parameter
  });
}

function getThirdDetail(parameter) {
  return request({
    url: `big/getThirdDetail`,
    method: "get",
    params: parameter
  });
}

function getRemoteSensingTime(){
  return request({
    url: `big/getRemoteSensingTimeList`,
    method: "get"
  });
}



export {
  baseurl,
  getAreasData,
  getDetail,
  getRemoteSenseTimeList,
  download,
  getStationDataList,
  getRegionTreeList,
  getRegionBoundary,
  uploadInspectionRecords,
  getPatrolDetail,
  getDistrictBareSoil,
  getBdRegionBoundary,
  getGdRegionBoundary,
  getThirdSelectItems,
  getThirdData,
  getThirdDetail,
  getRemoteSensingTime
};
